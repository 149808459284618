import React, { useState } from "react";
import "./FAQSection.css"; // Assurez-vous que le chemin est correct

function FAQSection() {
  const ACCORDION = [
    {
      id: 1,
      question: "Sommes-nous une entreprise virtuelle ?",
      response:
        "Nous sommes une entreprise physique avec une équipe compétente, dynamique et sérieuse dans divers domaines.",
    },
    {
      id: 2,
      question: "Pourquoi faire confiance à Bousanm ?",
      response:
        "Notre confiance se trouve dans la diversité de nos investissements et les différentes façons de faire circuler l’argent dans nos communautés.",
    },
    {
      id: 3,
      question: "Est-ce que la plateforme BOUSANM est fiable et sécurisée ?",
      response:
        "BOUSANM utilise des technologies de pointe pour détecter, mitiger et répondre immédiatement aux incidents.",
    },
  ];

  const [open, setOpen] = useState(null);

  const toggle = (id) => {
    setOpen(open === id ? null : id);
  };

  return (
    <section className="faq-9 faq section light-background" id="faq">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-5">
            <h2 className="faq-title">
              Vous avez une question ? Consultez la FAQ
            </h2>
            <p className="faq-description">Questions fréquemment posées</p>
          </div>
          <div className="col-lg-7">
            <div className="faq-container">
              <div className="accordion" id="accordionExample">
                {ACCORDION.map((item) => (
                  <div className="accordion-item" key={item.id}>
                    <h2 className="accordion-header" id={`heading${item.id}`}>
                      <button
                        className={`accordion-button ${
                          open === item.id ? "" : "collapsed"
                        }`}
                        type="button"
                        onClick={() => toggle(item.id)}
                        aria-expanded={open === item.id ? "true" : "false"}
                        aria-controls={`collapse${item.id}`}
                      >
                        {item.question}
                      </button>
                    </h2>
                    <div
                      id={`collapse${item.id}`}
                      className={`accordion-collapse collapse ${
                        open === item.id ? "show" : ""
                      }`}
                      aria-labelledby={`heading${item.id}`}
                    >
                      <div className="accordion-body">{item.response}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FAQSection;

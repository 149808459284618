import "./Portfolio.css"; // Assurez-vous d'importer le fichier CSS approprié
import React, { useState, useEffect } from "react";
import Lightbox from "./Lightbox"; // Importez votre composant Lightbox
import { Fade } from "react-awesome-reveal";

const images = [
  {
    src: "./img/plan-50.png",
    category: "Toutes",
    alt: "Plan 50 Gourdes par jours",
    descripion: "Plan 50 G",
    additionalImages: ["./img/plan-50-d.png", "./img/plan-50-d1.png"],
  },
  {
    src: "./img/plan-100.png",
    category: "jours",
    alt: "Portrait d’un dirigeant 3",
    descripion: "Plan 100 Gourdes par jours",
    additionalImages: ["./img/plan-100-d1.png", "./img/plan-100-d2.png"],
  },
  {
    src: "./img/plan-150.png",
    category: "jours",
    alt: "Plan 500 Gourdes par jours",
    descripion: "Plan 150 Gourdes par jours",
    additionalImages: ["./img/plan-150-d2.png", "./img/plan-150-d1.png"],
  },
  {
    src: "./img/plan-250.png",
    category: "jours",
    alt: "Plan 250 Gourdes par jours",
    descripion: "Plan 250 Gourdes par jours",
    additionalImages: ["./img/plan-250-d1.png", "./img/plan-250-d2.png"],
  },
  {
    src: "./img/plan-500.png",
    category: "jours",
    alt: "Plan 500 Gourdes par jours",
    descripion: "Plan 500 Gourdes par jours",
    additionalImages: ["./img/plan-500-d1.png", "./img/plan-500-d2.png"],
  },
  {
    src: "./img/plan-1000.png",
    category: "semaine",
    alt: "Plan 1000 Gourdes par jours",
    descripion: "Plan 1000 Gourdes par jours ou par semaine",
    additionalImages: ["./img/plan-1000-d1.png", "./img/plan-1000-d2.png"],
  },
  {
    src: "./img/plan-1500.png",
    category: "semaine",
    alt: "Plan 1500 Gourdes par jours",
    descripion: "Plan 1500 Gourdes par jours ou par semaine",
    additionalImages: ["./img/plan-1500-d1.png", "./img/plan-1500-d2.png"],
  },
  {
    src: "./img/plan-2500.png",
    category: "mois",
    alt: "Plan 2500 Gourdes par jours",
    descripion: "Plan 2500 Gourdes par jours, par semaine ou par mois",
    additionalImages: ["./img/plan-2500-d1.png", "./img/plan-2500-d2.png"],
  },
  {
    src: "./img/plan-3000.png",
    category: "mois",
    alt: "Plan 3000 Gourdes par jours",
    descripion: "Plan 3000 Gourdes par jours, par semaine ou par mois",
    additionalImages: ["./img/plan-3000-d1.png", "./img/plan-3000-d2.png"],
  },
  {
    src: "./img/plan-5000.png",
    category: "mois",
    alt: "Plan 5000 Gourdes par mois",
    descripion: "Plan 5,000 Gourdes par mois",
    additionalImages: ["./img/plan-5000-d1.png", "./img/plan-5000-d2.png"],
  },
  {
    src: "./img/plan-10000.png",
    category: "mois",
    alt: "Plan 10000 Gourdes par jours",
    descripion: "Plan 10,000 Gourdes par jours, par semaine ou par mois",
    additionalImages: ["./img/plan-10000-d0.png", "./img/plan-10000-d1.png"],
  },
  {
    src: "./img/plan-15000.png",
    category: "mois",
    alt: "Plan 500 Gourdes par jours",
    descripion: "Plan 15,000 Gourdes par jours, par semaine ou par mois ",
    additionalImages: ["./img/plan-15000-d1.png", "./img/plan-15000-d2.png"],
  },
  {
    src: "./img/plan-25000.png",
    category: "mois",
    alt: "Plan 25,000 Gourdes par jours",
    descripion: "Plan 25,000 Gourdes par jours, par semaine ou par mois ",
    additionalImages: ["./img/plan-25000-d1.png", "./img/plan-25000-d2.png"],
  },
];

const PortfolioSection = () => {
  const [lightboxImage, setLightboxImage] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("Toutes");
  const [fadeClass, setFadeClass] = useState("");

  const openLightbox = (image) => {
    // Si l'image a des images supplémentaires, on les passe, sinon on passe l'image principale
    const imagesToDisplay =
      image.additionalImages && image.additionalImages.length > 0
        ? image.additionalImages
        : [image.src]; // Utilise src comme seule image si pas d'images supplémentaires
    setLightboxImage({ images: imagesToDisplay, descripion: image.descripion });
  };

  const closeLightbox = () => {
    setLightboxImage(null);
  };

  const handleCategoryChange = (category) => {
    setFadeClass("fade-out");
    setTimeout(() => {
      setSelectedCategory(category);
      setFadeClass("fade-in");
    }, 400); // Correspond au temps de transition
  };

  const filteredImages =
    selectedCategory === "Toutes"
      ? images
      : images.filter((image) => image.category === selectedCategory);

  useEffect(() => {
    if (fadeClass === "fade-in") {
      const timer = setTimeout(() => setFadeClass(""), 400);
      return () => clearTimeout(timer);
    }
  }, [fadeClass]);

  return (
    <>
      <section className="container-fluid p-5 " id="portfolio">
        <div className="row align-items-center">
          <div className="col-12 gap-5 wow fadeInUp" data-wow-delay="0.1s">
            {/* <header
              className="text-center mx-auto mb-5 wow fadeInUp"
              data-wow-delay="0.5s"
              style={{ maxWidth: "600px" }}
            > */}
            <div className="container-fluid section-title">
              <Fade duration={1000}>
                <h2>Nos Plans</h2>
              </Fade>
              <Fade duration={1200} delay={200}>
                <p>Découvrez les plans détaillés ci-dessous.</p>
              </Fade>
            </div>
            {/* </header> */}
            <div className="card-body">
              <nav className="d-flex justify-content-center w-100 mb-3">
                <div
                  className="btn-group rounded wow fadeInLeft"
                  data-wow-delay="0.3s"
                >
                  <button
                    className={`btn btn-success ${
                      selectedCategory === "Toutes" ? "active" : ""
                    } btn-sm py-1`}
                    onClick={() => handleCategoryChange("Toutes")}
                  >
                    Toutes
                  </button>
                  <button
                    className={`btn btn-success ${
                      selectedCategory === "jours" ? "active" : ""
                    } btn-sm py-1`}
                    onClick={() => handleCategoryChange("jours")}
                  >
                    Jours
                  </button>
                  <button
                    className={`btn btn-success ${
                      selectedCategory === "semaine" ? "active" : ""
                    } btn-sm py-1`}
                    onClick={() => handleCategoryChange("semaine")}
                  >
                    Semaines
                  </button>
                  <button
                    className={`btn btn-success ${
                      selectedCategory === "mois" ? "active" : ""
                    } btn-sm py-1`}
                    onClick={() => handleCategoryChange("mois")}
                  >
                    Mois
                  </button>
                </div>
              </nav>
              <div
                className={`filter-container ${fadeClass} p-0 row wow fadeInUp`}
                data-wow-delay="0.5s"
              >
                {filteredImages.map((image, index) => (
                  <div
                    key={index}
                    className="filtr-item col-sm-2 mb-1 wow fadeInRight"
                    data-wow-delay="0.5s"
                  >
                    <a
                      href="#!"
                      onClick={(e) => {
                        e.preventDefault();
                        openLightbox(image); // Passe l'objet image complet
                      }}
                    >
                      <div className="image-card">
                        <img
                          src={image.src}
                          className="img-fluid"
                          alt={image.alt}
                          title={image.alt}
                          loading="lazy"
                        />
                        <div className="image-overlay">
                          <span className="text-white">
                            Voir les détails du plan
                          </span>
                          <hr className="divider" />
                          <i className="fas fa-plus"></i>
                        </div>
                      </div>
                    </a>
                  </div>
                ))}
              </div>
              {lightboxImage && (
                <Lightbox image={lightboxImage} onClose={closeLightbox} />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PortfolioSection;
